import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(private http: HttpClient) {
  }

  translateObject: Object = {};
  defaultTranslateObject: Object = {};
  supportedLang: Array<String> = ['en-US', 'da-DK', 'de-DE', 'en-GB', 'en-US',
    'es-EC', 'es-ES', 'et-EE', 'fi-FI', 'fr-FR', 'hu-HU', 'id-ID', 'it-IT', 'ja-JP', 'ko-KR',
    'lt-LT', 'ms-MY', 'nb-NO', 'nl-NL', 'pl-PL', 'pt-BR', 'pt-PT', 'ru-RU', 'sk-SK', 'sv-SE',
    'th-TH', 'tr-TR', 'vi-VN', 'zh-CN', 'zh-HK'];

  async setLang(userlocale: any) {
    let userLangCode = userlocale || navigator.language;
    let locale: any;
    //handling & matching dialects
    //match against supported languages
    if (this.supportedLang.indexOf(userLangCode) > -1) {
      locale = userLangCode;
    } else {
      //match language part against supported languages 
      let langIndex = this.supportedLang.indexOf(userLangCode.split('-')[0]);
      //match language part against supported languages 
      if (langIndex > -1) {
        locale = this.supportedLang[langIndex];
      } else {
        //match language part against language part of supported languages ignoring the dialect
        locale = this.supportedLang.find(function (lang) {
          if (lang.split('-')[0] == userLangCode.split('-')[0]) {
            return lang;
          }
        });
        if (!locale) {
          if (this.supportedLang.indexOf(navigator.language) > -1) {
            locale = navigator.language;
          } else {
            //match browser lang against supported languages 
            langIndex = this.supportedLang.indexOf(navigator.language.split('-')[0]);
            if (langIndex > -1) {
              locale = this.supportedLang[langIndex];
            } else {
              //match language part against language part of supported languages ignoring the dialect
              locale = this.supportedLang.find(function (lang) {
                if (lang.split('-')[0] == navigator.language.split('-')[0]) {
                  return lang;
                }
              }) || 'en-US';
            }
          }
        }
      }
    }
    const langPath = `assets/locales/${locale}.json`;
    let obj = await this.http.get(langPath).toPromise();
    const defaultLangPath = `assets/locales/en-US.json`;
    if (langPath != defaultLangPath) {
      var objDefault = await this.http.get(defaultLangPath).toPromise();
      this.defaultTranslateObject = objDefault!;
    }
    this.translateObject = obj!;
  }

  translate(text: string) {
    return this.translateObject[text as keyof typeof this.translateObject]
      || this.defaultTranslateObject[text as keyof typeof this.translateObject] || text;
  }
}
