import { Injectable } from '@angular/core';
import { formatNumber, registerLocaleData } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class GlobalizeService {
    constructor() {
    }

    //format a number into cuture specific string
    static formatNumber(inputNum: number, culture: string) {
        return isNaN(inputNum) ? '' : formatNumber(inputNum, culture, '1.2-2');
    }

    //unformat a number via given locale 
    static unformatNumber(formattedNumber: string, culture: string) {
        let unformattedNumber = '';
        let decimalPart = '';
        let wholePart = '';
        let numberPattern = GlobalizeService.getNumberStructure(culture);
        if (formattedNumber.split(numberPattern.decimal!).length - 1 >= 0) {
            if (formattedNumber.split(numberPattern.decimal!).length - 1 != 0) {
                if (/\s+/g.test(numberPattern.decimal!)) {
                    decimalPart = formattedNumber.replaceAll(/\s+/g, '.');
                } else {
                    decimalPart = formattedNumber.replaceAll(numberPattern.decimal!, '.');
                }
                decimalPart = decimalPart.split('.')[formattedNumber.replaceAll(numberPattern.decimal!, '.').split('.').length - 1];
            }
        }
        if (formattedNumber.split(numberPattern.thousand!).length - 1 >= 0) {
            if (/\s+/g.test(numberPattern.thousand!)) {
                wholePart = formattedNumber.replaceAll(/\s+/g, '')
            } else {
                wholePart = formattedNumber.replaceAll(numberPattern.thousand!, '')
            }
            wholePart = wholePart.split(numberPattern.decimal!)[0];
        }
        try {
            unformattedNumber = Number(wholePart + '.' + decimalPart).toString();
        } catch (err) {
            unformattedNumber = '';
        }
        return unformattedNumber;
    }

    static unformatAnyNumber(inputLocaleString: string) {
        if (!inputLocaleString) {
            return '';
        }
        var outputNumberStr: any;
        const getLastNonNumericChar = (inputStr: string) => {
            let i = inputStr.length - 1;
            while (i >= 0 && /\d/.test(inputStr[i])) {
                i--;
            }
            const lastNonNumericChar = i >= 0 ? inputStr[i] : null;
            return lastNonNumericChar;
        };

        let lastNonNumericCharInLocaleStr = getLastNonNumericChar(inputLocaleString);

        //const lastNonNumericChar = i >= 0 ? localeString[i] : null;
        if (lastNonNumericCharInLocaleStr) {
            let possibleDecimalSeparators = ',.٫';
            let possibleThousandSeparators = '’,. ٫\'';
            let negativePrefix = '';
            if (possibleDecimalSeparators.indexOf(lastNonNumericCharInLocaleStr) == -1) {
                if (inputLocaleString.indexOf('−') == 0 || inputLocaleString.indexOf('-') == 0) {
                    negativePrefix = '-';
                }
                outputNumberStr = negativePrefix + inputLocaleString.replaceAll(lastNonNumericCharInLocaleStr, '')
            } else {
                let splitDecmal = inputLocaleString.split(lastNonNumericCharInLocaleStr)
                let wholePart = ''
                for (var j = 0; j < splitDecmal.length - 1; j++) {
                    wholePart += splitDecmal[j]
                }
                let decimalPart = splitDecmal[splitDecmal.length - 1];
                let i = possibleThousandSeparators.length - 1;
                let copiedWhole = JSON.parse(JSON.stringify(wholePart));
                while (i >= 0) {
                    if (/\s+/g.test(possibleThousandSeparators[i])) {
                        copiedWhole = copiedWhole.replaceAll(/\s+/g, '')
                    } else {
                        copiedWhole = copiedWhole.replaceAll(possibleThousandSeparators[i], '')
                    }
                    i--;
                }
                if (wholePart.indexOf('-') == 0 || wholePart.indexOf('−') == 0) {
                    negativePrefix = '-';
                    copiedWhole = copiedWhole.substring(1, copiedWhole.length);
                    wholePart = wholePart.substring(1, wholePart.length);
                }
                let lastNonNumericCharInWholeStr = getLastNonNumericChar(wholePart);
                if (wholePart == copiedWhole && lastNonNumericCharInWholeStr) {
                    outputNumberStr = negativePrefix + copiedWhole + '' + decimalPart
                } else {
                    //commented intentionally, do not delete
                    // if (wholePart == copiedWhole && decimalPart.length >= 3) {
                    //     outputNumberStr = negativePrefix + copiedWhole + '' + decimalPart
                    // } else {
                    outputNumberStr = negativePrefix + copiedWhole + '.' + decimalPart
                    // }
                }
            }
        } else {
            outputNumberStr = inputLocaleString;
        }
        if (!isNaN(outputNumberStr)) {
            outputNumberStr = Number(outputNumberStr).toFixed(2);
        }
        return outputNumberStr;
    }

    //get culture specific number decimal & thousand separator
    static getNumberStructure(culture: string) {
        const mapperRE = /(.{1})\d{3}(\D)\d$/,
            digitRE = /\d/,
            formattedValue = (new Intl.NumberFormat(culture ?? navigator.language)).format(10000.1);
        let [_, thousand, decimal] = mapperRE.exec(formattedValue) ?? [null, null, null];
        //In case the captured position is number it means there's no thousand separator
        if (digitRE.test(thousand!))
            thousand = '';
        //additional code check to handle ascii values(space)
        if (thousand?.charCodeAt(0) == 160 || thousand?.charCodeAt(0) == 8239) {
            thousand = String.fromCharCode(32)
        }
        if (decimal?.charCodeAt(0) == 160 || decimal?.charCodeAt(0) == 8239) {
            decimal = String.fromCharCode(32)
        }
        //special case handling required due to different arabic numerals
        if (culture == 'ar-SA') {
            thousand = ',';
            decimal = '.';
        }
        return { thousand, decimal }
    }

    //register locale in angular
    async registerLocale(culture: string) {
        let localeModule: any;
        try {
            localeModule = await import(
                `node_modules/@angular/common/locales/${culture}.mjs`
            )
        } catch (err) {
            try {
                localeModule = await import(
                    `node_modules/@angular/common/locales/${culture.toLowerCase()}.mjs`
                )
            } catch (err) {
                try {
                    localeModule = await import(
                        `node_modules/@angular/common/locales/${culture.split('-')[0]}.mjs`
                    )
                } catch (err) {
                    localeModule = await import(
                        `@angular/common/locales/en`
                    )
                }
            }
        }
        registerLocaleData(localeModule.default)
    }
}