export const DecimalFields = ["quantity", "unitPrice", "taxRate", "taxSum", "netTotal"];

export interface InvoiceData {
  invoiceNumber: string;
  invoiceDate: string;
  customerName: string;
  accountCode: string;
  amount: number;
}

export class InvoiceLineData {
  rowNumber: number;
  productCode?: { value: string, data: any };
  description?: { value: string, data: any };
  // date: string;
  quantity?: { value: string, data: any };
  unit?: { value: string, data: any };
  unitPrice?: { value: string, data: any };
  taxRate?: { value: string, data: any };
  taxSum?: { value: string, data: any };
  netTotal?: { value: string, data: any };

  constructor(rowNumber: any) {
    this.rowNumber = rowNumber;
    this.productCode = { value: '', data: {} };
    this.description = { value: '', data: {} };
    // this.date = '';
    this.quantity = { value: '', data: {} };
    this.unit = { value: '', data: {} };
    this.unitPrice = { value: '', data: {} };
    this.taxRate = { value: '', data: {} };
    this.taxSum = { value: '', data: {} };
    this.netTotal = { value: '', data: {} };
  }
}

export class TaskResponseLineItem {
  "type": string;
  "value": string;
  "owner": string;
  "page": number;
  "x": number;
  "y": number;
  "width": number;
  "height": number;
  "dpi": number;
}


export const toGridModel: any = {
  "LINE_ITEM.ITEM.NAME": "description",
  "LINE_ITEM.LINE_EXTENSION.AMOUNT": "netTotal",
  "LINE_ITEM.ITEM.SELLERS_ITEM": "productCode",
  "LINE_ITEM.TAX_TOTAL.AMOUNT": "taxSum",
  "LINE_ITEM.PRICE.AMOUNT": "unitPrice",
  "LINE_ITEM.QUANTITY.AMOUNT": "quantity",
  "LINE_ITEM.QUANTITY.UNITCODE": "unit",
  "LINE_ITEM.ITEM.TAX_PERCENT": "taxRate",
}

export const fromGridModel: any = {
  "description": "LINE_ITEM.ITEM.NAME",
  "netTotal": "LINE_ITEM.LINE_EXTENSION.AMOUNT",
  "productCode": "LINE_ITEM.ITEM.SELLERS_ITEM",
  "taxSum": "LINE_ITEM.TAX_TOTAL.AMOUNT",
  "unitPrice": "LINE_ITEM.PRICE.AMOUNT",
  "quantity": "LINE_ITEM.QUANTITY.AMOUNT",
  "unit": "LINE_ITEM.QUANTITY.UNITCODE",
  "taxRate": "LINE_ITEM.ITEM.TAX_PERCENT"
}
