import { GlobalizeService } from "src/app/app-services/globalize.service";
import { DecimalFields, fromGridModel, TaskResponse, GridTaxData, toGridModel } from "./taxes-and-sums.model";
import { AppConfig } from "src/app/app.config";
import { AppUtils } from "../app-util.service";

export function mapToInvoiceDataModel(items: any[]) {
    let _taxData: GridTaxData[] = [];
    items.forEach((_item: any[], index) => {
        let data: any = {};
        data["rowNumber"] = index + 1;
        _item.forEach(item => {
            let key = toGridModel[item["type"]];
            if (key) {
                if (DecimalFields.includes(key) && !isNaN(Number(item["value"]))) {
                    data[key] = { value: AppUtils.localizeDecimal(item["value"]), data: item }
                } else {
                    data[key] = { value: item["value"].toString(), data: item }
                }
            }
        })
        _taxData.push(data);
    })
    return _taxData;
}

export function mapToTaskResponseTaxData(gridItems: GridTaxData[], taxData: TaskResponse[][]) {
    let _updatedItems: any[][] = []
    gridItems.forEach((gridData: any) => {
        let _existingData: TaskResponse[] = taxData[gridData.rowNumber - 1];
        let new_item: any[] = [];
        if (_existingData) {
            // update field value of existing rows 
            new_item = updateValueInExistingFieldData(_existingData, gridData);
        } else {
            // insert new rows from grid to response json
            Object.keys(gridData).forEach(key => {
                if (key != 'rowNumber' && gridData[key]['value'].length > 0) {
                    let item: any = {};
                    item = gridData[key]['data'];
                    item["type"] = fromGridModel[key];
                    item["value"] = gridData[key]['value'];
                    checkAndExtractDecimalValue(item, key, gridData[key]['value'])
                    new_item.push(item);
                }
            })
        }
        if (new_item.length > 0) {
            _updatedItems.push(new_item);
        }
    })
    return _updatedItems;
}

function checkAndExtractDecimalValue(item: any, key: string, value: string) {
    if (DecimalFields.includes(key)) {
        let extractedNumber = GlobalizeService.unformatNumber(value, AppConfig.USER_CULTURE);
        if (!isNaN(Number(extractedNumber))) {
            item["value"] = Number(extractedNumber);
        }
    }
}

function updateValueInExistingFieldData(_existingData: TaskResponse[], gridData: any) {
    let new_item: any[] = [..._existingData];
    Object.keys(gridData).forEach(key => { 
        if (key != 'rowNumber' && gridData[key]['value'].length > 0) {
            let field = new_item.find(item => item.type === fromGridModel[key]);
            if (field) {
                field.value = gridData[key]['value'];
                updateImageCoordinates(gridData, key, field);
                checkAndExtractDecimalValue(field, key, gridData[key]['value'])
                // new_item.push(field);
            } else {
                let item: any = {};
                item = gridData[key]['data'];
                item["type"] = fromGridModel[key];
                item["value"] = gridData[key]['value'];
                checkAndExtractDecimalValue(item, key, gridData[key]['value'])
                new_item.push(item);
            }
        }
    })
    return new_item;
}

function updateImageCoordinates(gridData: any, key: string, item: any) {
    gridData[key]['data']['page'] && (item["page"] = gridData[key]['data']['page']);
    gridData[key]['data']['x'] && (item["x"] = gridData[key]['data']['x']);
    gridData[key]['data']['y'] && (item["y"] = gridData[key]['data']['y']);
    gridData[key]['data']['width'] && (item["width"] = gridData[key]['data']['width']);
    gridData[key]['data']['height'] && (item["height"] = gridData[key]['data']['height']);
    gridData[key]['data']['dpi'] && (item["dpi"] = gridData[key]['data']['dpi']);
}