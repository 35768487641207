import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GridTaxData } from './taxes-and-sums.model';
import { LocalizationService } from 'src/app/app-services/localization.service';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { AppUtils } from '../app-util.service';
import { mapToInvoiceDataModel } from './taxes-and-sums.mapping';
import { DecimalValidator } from 'src/app/validators/decimal.validator';
import { EditableValidatingGridForTaxes } from './editable-validating-grid/editable-validating-grid.component';
import { GlobalizeService } from 'src/app/app-services/globalize.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'taxes-and-sums',
  templateUrl: './taxes-and-sums.component.html',
  styleUrls: ['./taxes-and-sums.component.scss']
})
export class TaxesAndSumsComponent extends BaseTranslationService implements OnInit {

  @ViewChild(EditableValidatingGridForTaxes) editableChild: EditableValidatingGridForTaxes;

  @Input() taxData: any[][];
  @Input() showWarning: boolean = false;
  @Input() isDisabled: boolean = true;
  @Output() updatedTaxData = new EventEmitter<any>();
  @Output() isTaxDataValid = new EventEmitter<any>();
  @Output() readFocusOnCanvas = new EventEmitter<any>();

  _taxData: GridTaxData[] = [];
  isTaxDataValid$: boolean = true;
  reloadGrid: boolean = false;

  constructor(localization: LocalizationService) {
    super(localization);
  }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("taxData" in changes) {
      this._taxData = [];
      this.isTaxDataValid$ = true;
      this._taxData = mapToInvoiceDataModel([...this.taxData]);
      this.reloadGrid = !this.reloadGrid;
    }
  }

  addTaxData() {
    this._taxData = [new GridTaxData(this._taxData.length + 1)]
  }

  updatedRowData(e: any) {
    this._taxData = e;
    this.updatedTaxData.emit(e);
  }

  _isTaxDataValid(e: any) {
    this.isTaxDataValid$ = e;
    this.isTaxDataValid.emit(e);
  }

  _readFocusOnCanvas(e: any) {
    this.readFocusOnCanvas.emit(e);
  }

  updateCellValue(rowId: any, columnIndex: any, updatedCellData: any) {
    if (updatedCellData.value) {
      // fommat to localized number or return as is 
      if (!DecimalValidator.decimalValidatorForValue(updatedCellData.value) && !isNaN(Number(GlobalizeService.unformatNumber(updatedCellData.value, AppConfig.USER_CULTURE)))) {
        updatedCellData.value = AppUtils.localizeDecimal(updatedCellData.value);
      };
    }
    this.editableChild.updateGridCellValue(rowId, columnIndex, updatedCellData);
  }
}

