import { Injectable, OnDestroy } from '@angular/core';
import {
  EditableGridDefaultValidationService,
  EditableGridManager,
  EditableGridValidationManager,
} from '@basware/gt-editable-grid';
import {
  GravitonGridCommandEventTypes,
  GravitonGridEvent,
  UpdateRowsCommandEvent,
  ValidationHelper,
  ValidationsChangeEvent,
  ValidationSlot,
  ValidationType,
} from '@basware/gt-grid';
import { AgGridEvent, CellValueChangedEvent, Events } from 'ag-grid-community';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { DecimalFields, GridTaxData } from '../../taxes-and-sums.model';
import { DecimalValidator } from 'src/app/validators/decimal.validator';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';

/**
 * An example how an application can implement a caching validation manager. This type of validation
 * manager avoids causing rendering lag in the grid due to validation results being pre-calculated in
 * the cache and available in O(1) time for individual cells.
 *
 * The implication of this is that the application now has to manage the cache and it's invalidation.
 * How complex this is depends on the application and functionality that it implements on the grid. In
 * this example there is a row delete action and the cache needs to be cleared of results related to deleted
 * rows.
 *
 * In this example only values that are produced by the user directly editing cells are validated.
 * In your application you should consider what the validation requirements are
 *  - do you need to validate the initial data you are setting to the grid
 *  - are there other ways cells can change their values
 */
@Injectable()
export class EditableValidatingGridValidationManager
  extends EditableGridValidationManager
  implements OnDestroy {
  private validationCache = new ValidationCache();
  private destroy$ = new Subject<void>();
  private isValidSubject = new BehaviorSubject<boolean>(true);
  // by not exposing the Subject you are limiting the API of the class.
  // exposing the underlying Subject would allow the user of the class
  // to call .next() on it.
  public isValid$ = this.isValidSubject.asObservable();

  constructor(
    private baseTranslation: BaseTranslationService,
    gridManager: EditableGridManager<GridTaxData>,
    defaultValidationService: EditableGridDefaultValidationService // contains validators for minValue, maxValue etc.
  ) {
    super(gridManager, defaultValidationService);
    this.manager.events$
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => this.onGridEvent(event));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Override the method to get validation from the cache
  // The grid will call this method every time when rendering a grid cell with a validation icon
  override getValidations(
    rowId: string,
    fieldName?: string
  ): ValidationSlot | undefined {
    return this.validationCache.get(rowId, fieldName);
  }

  /** Clear validation cache */
  clear(): void {
    this.validationCache.clear();
    this.manager.dispatch(new ValidationsChangeEvent());
  }

  validateGridData() {
    this.manager.api.forEachNode(row => {
      Object.keys(row.data).forEach(fieldName => {
        const validation = this.performValidation(row.id!, fieldName, row.data[fieldName]["value"]);
        this.validationCache.set(row.id!, fieldName, validation);

      })
    })
    // notifies the grid of new validation results - causes cells to rerender and display
    // validation icons
    this.manager.dispatch(new ValidationsChangeEvent());
    const hasErrors = this.validationCache.check(ValidationType.ERROR);
    this.isValidSubject.next(!hasErrors);
  }

  setWarningValidation(rowId: string, coldId: string) {
    let fieldValidation = this.validationCache.get(rowId, coldId);
    // when higher order validation is already present, do not overwrite it
    if (fieldValidation?.status! > ValidationType.WARNING) {
      return;
    }
    let validation: ValidationSlot = {
      status: ValidationType.WARNING,
      messages: [{
        type: ValidationType.WARNING,
        message: this.baseTranslation.tr("spdf.app.module.bu.validation.taxesAndSums.field.warning").toString()
      }]
    }
    this.validationCache.set(rowId, coldId, validation);
    this.manager.dispatch(new ValidationsChangeEvent());
  }

  clearWarningValidation(rowId: string, coldId: string) {
    let fieldValidation = this.validationCache.get(rowId, coldId);
    if (fieldValidation?.status! <= ValidationType.WARNING)
      this.validationCache.set(rowId, coldId, undefined);
    this.manager.dispatch(new ValidationsChangeEvent());
  }

  // Update the cache when data in the grid is changed. Potentially heavy validation
  // logic is executed when reacting to writes, not when accessing the data.
  private onGridEvent(
    event: GravitonGridEvent | AgGridEvent<GridTaxData>
  ): void {
    switch (event.type) {
      case Events.EVENT_CELL_VALUE_CHANGED: {
        // a cell value was changed, most likely by user editing it
        // perform validation for the new value and cache it
        const cellValueChangedEvent = event as CellValueChangedEvent;
        const rowValue = cellValueChangedEvent.newValue;
        const rowId = cellValueChangedEvent.node.id;
        const fieldName = cellValueChangedEvent.column.getColDef().field;
        if (rowId === undefined || fieldName === undefined) {
          return;
        }
        const validation = this.performValidation(rowId, fieldName, rowValue);
        this.validationCache.set(rowId, fieldName, validation);
        // notifies the grid of new validation results - causes cells to rerender and display
        // validation icons
        this.manager.dispatch(new ValidationsChangeEvent());
        const hasErrors = this.validationCache.check(ValidationType.ERROR);
        this.isValidSubject.next(!hasErrors);
        return;
      }
      case GravitonGridCommandEventTypes.UpdateRows: {
        // the app has emitted this event to request the grid to update rowdata via a grid transaction
        // clear the cache of results for removed rows
        const updateRowsEvent = event as UpdateRowsCommandEvent;
        const rowsToBeRemoved = updateRowsEvent.updates.remove;
        rowsToBeRemoved.forEach(rowData =>
          this.validationCache.clearRow(rowData['rowNumber'].toString())
        );
        const hasErrors = this.validationCache.check(ValidationType.ERROR);
        this.isValidSubject.next(!hasErrors);
        return;
      }
    }
  }

  private performValidation(rowId: string, fieldName: string, value: string) {
    if (DecimalFields.includes(fieldName)) {
      if (DecimalValidator.decimalValidatorForValue(value)) {
        return {
          status: ValidationType.ERROR,
          messages: [{
            type: ValidationType.ERROR,
            message: this.baseTranslation.tr("spdf.app.module.bu.validation.field.incorrectFormat").toString()
          }]
        } as ValidationSlot
      }
    }
    // if (!value || value.length == 0) {
    //   return { status: ValidationType.WARNING, messages: [{ type: ValidationType.WARNING }] } as ValidationSlot
    // }
    return this.getDefaultValidations(rowId, fieldName);
  }
}

/** Validation results of individual fields in a row, key is the fieldName */
type RowValidationCache = Map<string, ValidationSlot>;

/** Simple cache for storing precalculated validation results for grid cells */
class ValidationCache {
  /** rows with validation results, key is the rowId */
  private cache = new Map<string, RowValidationCache>();

  public get(rowId: string, fieldName?: string): ValidationSlot | undefined {
    const rowCache = this.cache.get(rowId);
    if (!rowCache) {
      return undefined;
    }
    if (fieldName) {
      return rowCache.get(fieldName);
    }
    // Summarize validation for the entire row
    return ValidationHelper.concatValidationSlots(
      Array.from(rowCache.values())
    );
  }

  public set(
    rowId: string,
    fieldName: string,
    validation: ValidationSlot | undefined
  ): void {
    const rowCache = this.cache.has(rowId)
      ? this.cache.get(rowId)
      : this.cache.set(rowId, new Map<string, ValidationSlot>()).get(rowId);
    validation !== undefined
      ? rowCache?.set(fieldName, validation)
      : rowCache?.delete(fieldName);
  }

  public clear(): void {
    this.cache.clear();
  }

  public clearRow(rowId: string): void {
    this.cache.delete(rowId);
  }

  /**
   * Check for any cached validation results at the given validation level or worse
   * @param level Validation result type to check - are there result at this level or worse
   * @returns true if there are cached validation results at the given level or worse
   */
  public check(level: ValidationType): boolean {
    return Array.from(this.cache.values()).some(rowValidationCache =>
      Array.from(rowValidationCache.values()).some(
        fieldValidation => fieldValidation.status >= level
      )
    );
  }
}
